body, html {
  height: 100%;
  margin: 0;
  background-color: #f0f0f0; /* Background color for the entire page */
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center;     /* Center horizontally */
  flex: 1;                 /* Occupy remaining height */
  text-align: right;

}

.buttons-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-button {
  margin: 10px;
  padding: 0;
  border: none;
  border-radius: 8px;
  border: 2px solid black;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px; /* Button width */
  height: 150px; /* Button height */
  position: relative; /* Relative positioning */
  overflow: hidden; /* Hide content that overflows the button */
}

.text-only {
  background-color: rgb(145, 175, 202);
  color: black;
  font-size: 18px;
  font-weight: bold;
}


.custom-button img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Fit the image without cropping */
  border-radius: 8px; /* Rounded corners like the button */
}

.account-name {
  margin-top: 5px;
  text-align: center;
  color: #000; /* Text color for the account name */
  font-size: 1rem;
}

@media (max-width: 600px) {
  .custom-button {
    width: 100px; /* Button width for smaller screens */
    height: 100px; /* Button height for smaller screens */
  }
}

.error-message {
  text-align: center;
  color: #d9534f; /* צבע אדום כהה להדגשת השגיאה */
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

